// for ref on different environments.
export const envNames = {
  local: "local", // localhost
  development: "development", // individual firebase project for developers => https://trash-warrior-dev-vp.web.app/
  staging: "staging", // main development app                       => https://trash-warrior-dev.web.app/
  production: "production", // final production app
};

export const isProduction = process.env.REACT_APP_ENV === envNames.production;

export const isStaging = process.env.REACT_APP_ENV === envNames.staging;
export const isDevelopment = process.env.REACT_APP_ENV === envNames.development;

// for all development environments
export const isDev = [envNames.staging, envNames.development, envNames.local].includes(process.env.REACT_APP_ENV);
